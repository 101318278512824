import React, { useState, useContext, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import JSONCompare from 'json-deep-compare' // Use the npm package instead of local implementation
// import { compareJSON } from '../../utils/json-deep-compare'
import ThemeContext from '../../context/ThemeContext'
import config from '../../../data/SiteConfig'

// Lazy loaded components
const Layout = lazy(() => import('../../layout'))

// Style objects
const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 1.5rem',
  },
  title: {
    marginBottom: '1rem',
  },
  description: {
    marginBottom: '2rem',
    fontSize: '1.1rem',
  },
  compareContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  compareContainerDesktop: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
  },
  jsonInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '1rem',
  },
  editorContainer: {
    position: 'relative',
  },
  jsonEditor: (isDark) => ({
    width: '100%',
    minHeight: '400px',
    padding: '1rem',
    fontFamily: 'monospace',
    border: `1px solid ${isDark ? '#444' : '#ddd'}`,
    backgroundColor: isDark ? '#2d2d2d' : '#f9f9f9',
    color: isDark ? '#eee' : '#333',
    borderRadius: '4px',
    resize: 'vertical',
  }),
  configPanel: (isDark) => ({
    margin: '2rem 0',
    padding: '1.5rem',
    borderRadius: '8px',
    backgroundColor: isDark ? '#333' : '#f0f0f0',
  }),
  configTitle: {
    marginBottom: '1rem',
  },
  configGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
  },
  configItem: {
    marginBottom: '1rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    fontWeight: 500,
  },
  input: (isDark) => ({
    width: '100%',
    padding: '0.5rem',
    border: `1px solid ${isDark ? '#444' : '#ddd'}`,
    backgroundColor: isDark ? '#2d2d2d' : '#fff',
    color: isDark ? '#eee' : '#333',
    borderRadius: '4px',
  }),
  compareButton: {
    display: 'block',
    width: '100%',
    maxWidth: '200px',
    margin: '1rem auto',
    padding: '0.75rem 1.5rem',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  compareButtonHover: {
    backgroundColor: '#0056b3',
  },
  resultsContainer: (isDark, visible) => ({
    marginTop: '2rem',
    padding: '1.5rem',
    borderRadius: '8px',
    backgroundColor: isDark ? '#333' : '#f0f0f0',
    display: visible ? 'block' : 'none',
  }),
  resultSection: {
    marginBottom: '1.5rem',
  },
  summaryBox: (isDark) => ({
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    backgroundColor: isDark ? '#444' : '#e9e9e9',
  }),
  match: {
    color: '#28a745',
  },
  unmatch: {
    color: '#dc3545',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
  },
  actionButton: (isDark) => ({
    padding: '0.5rem 1rem',
    backgroundColor: isDark ? '#444' : '#e9e9e9',
    color: isDark ? '#eee' : '#333',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  }),
  actionButtonHover: (isDark) => ({
    backgroundColor: isDark ? '#555' : '#d9d9d9',
  }),
};

const JsonCompareTool = () => {
  const theme = useContext(ThemeContext)
  const isDark = theme?.dark || false
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768
  
  const [json1, setJson1] = useState('')
  const [json2, setJson2] = useState('')
  const [strictTypeChecking, setStrictTypeChecking] = useState(true)
  const [ignoreKeys, setIgnoreKeys] = useState('')
  const [equivalentValues, setEquivalentValues] = useState('')
  const [ignoreExtraKeys, setIgnoreExtraKeys] = useState(false)
  const [useRegex, setUseRegex] = useState(false)
  const [regexPatterns, setRegexPatterns] = useState('')
  const [results, setResults] = useState(null)
  const [compareError, setCompareError] = useState(null)
  const [showResults, setShowResults] = useState(false)
  const [hoveredButton, setHoveredButton] = useState(null)

  const handleCompare = () => {
    try {
      const parsedJson1 = JSON.parse(json1)
      const parsedJson2 = JSON.parse(json2)
      
      // Reset any previous errors
      setCompareError(null)
      
      try {
        // Create the validator with the specified options
        const apiValidator = new JSONCompare({
          // If ignoreKeys is specified, convert to the format expected by the library
          ignoredKeys: ignoreKeys.trim() ? ignoreKeys.split(',').map(key => key.trim()) : [],
          
          // If equivalentValues is specified, parse and use it
          equivalentValues: equivalentValues.trim() ? JSON.parse(equivalentValues) : {},
          
          // Use the ignoreExtraKeys setting
          ignoreExtraKeys: ignoreExtraKeys,
          
          // Use the strictTypeChecking setting (inverse of strictTypes in the example)
          strictTypes: strictTypeChecking,
          
          // If regex validation is enabled, parse and use regexPatterns
          regexChecks: useRegex && regexPatterns.trim() ? JSON.parse(regexPatterns) : {},
          
          // Match keys by name for regex validation
          matchKeysByName: true
        });
        
        // Log the comparison parameters for debugging
        console.log('Compare options:', {
          ignoredKeys: ignoreKeys.trim() ? ignoreKeys.split(',') : [],
          equivalentValues: equivalentValues.trim() ? JSON.parse(equivalentValues) : {},
          ignoreExtraKeys,
          strictTypes: strictTypeChecking,
          regexChecks: useRegex && regexPatterns.trim() ? JSON.parse(regexPatterns) : {},
        });
        
        // Perform the comparison
        const validationResult = apiValidator.compare(parsedJson1, parsedJson2);
        console.log('Validation Result:', validationResult);
        
        if (validationResult) {
          // Transform the result to match our UI expectations
          const transformedResult = {
            matchPercentage: validationResult.summary?.matchPercentage || 0,
            matchedKeys: [],
            unmatchedKeys: [],
            typeMismatches: {},
            regexValidationFailures: {}
          };
          
          // Process matched keys (not directly provided in the example)
          if (validationResult.matched && validationResult.matched.values) {
            transformedResult.matchedKeys = validationResult.matched.values.map(item => item.path);
          }
          
          // Process unmatched keys
          if (validationResult.unmatched && validationResult.unmatched.values) {
            transformedResult.unmatchedKeys = validationResult.unmatched.values.map(item => item.path);
            
            // Build type mismatches
            validationResult.unmatched.values.forEach(item => {
              if (typeof item.expected !== typeof item.actual) {
                transformedResult.typeMismatches[item.path] = {
                  expected: typeof item.expected,
                  actual: typeof item.actual
                };
              }
            });
          }
          
          // Process regex validation failures
          if (validationResult.regexChecks && validationResult.regexChecks.failed) {
            validationResult.regexChecks.failed.forEach(item => {
              transformedResult.regexValidationFailures[item.path] = item.message;
            });
          }
          
          // Store the transformed result
          setResults(transformedResult);
          setShowResults(true);
        } else {
          // Handle empty results
          setCompareError('The comparison library returned an empty result');
          
          // Create a fallback result with a basic comparison
          const fallbackResult = {
            matchPercentage: 0,
            matchedKeys: [],
            unmatchedKeys: [],
            typeMismatches: {}
          }
          
          // Attempt basic comparison
          const allKeys1 = new Set(Object.keys(parsedJson1))
          const allKeys2 = new Set(Object.keys(parsedJson2))
          
          // Find matched keys
          const matched = [...allKeys1].filter(key => 
            allKeys2.has(key) && 
            JSON.stringify(parsedJson1[key]) === JSON.stringify(parsedJson2[key])
          )
          fallbackResult.matchedKeys = matched
          
          // Find unmatched keys
          const unmatched = [
            ...[...allKeys1].filter(key => !allKeys2.has(key) || 
              JSON.stringify(parsedJson1[key]) !== JSON.stringify(parsedJson2[key])),
            ...[...allKeys2].filter(key => !allKeys1.has(key))
          ]
          fallbackResult.unmatchedKeys = [...new Set(unmatched)]
          
          // Calculate match percentage
          const totalUniqueKeys = new Set([...allKeys1, ...allKeys2]).size
          fallbackResult.matchPercentage = totalUniqueKeys ? matched.length / totalUniqueKeys : 0
          
          setResults(fallbackResult)
          console.log('Using fallback comparison:', fallbackResult)
        }
      } catch (compareError) {
        console.error('Comparison error:', compareError)
        setCompareError(`Comparison error: ${compareError.message || 'Unknown error during comparison'}`)
        alert(`Comparison error: ${compareError.message || 'Unknown error during comparison'}`)
      }
    } catch (error) {
      console.error('General error:', error)
      setCompareError(`Error: ${error.message}`)
      alert(`Error: ${error.message}`)
    }
  }

  const handleLoadSample = () => {
    setJson1(JSON.stringify({
      name: "Ashmeet Sehgal",
      age: 30,
      isActive: true,
      address: {
        street: "123 Main St",
        city: "Bangalore",
        zip: "560103"
      },
      tags: ["developer", "javascript", "react"]
    }, null, 2))
    
    setJson2(JSON.stringify({
      name: "Ashmeet Sehgal",
      age: "30", // String instead of number
      address: {
        street: "123 Main St",
        city: "New York",
        zip: 151001 // Number instead of string
      },
      tags: ["developer", "javascript", "vue"],
      newField: "simple guy" // Extra field
    }, null, 2))
  }

  const handleCopyResults = () => {
    if (results) {
      navigator.clipboard.writeText(JSON.stringify(results, null, 2))
      alert('Results copied to clipboard!')
    }
  }

  const handleDownloadResults = () => {
    if (results) {
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(results, null, 2))
      const downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute("href", dataStr)
      downloadAnchorNode.setAttribute("download", "json-comparison-results.json")
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    }
  }

  const renderMatchedKeys = () => {
    if (!results || !results.matchedKeys || results.matchedKeys.length === 0) {
      console.log('No matched keys to render')
      return <p>No matched keys</p>
    }
    console.log('Rendering matched keys:', results.matchedKeys)
    return (
      <ul>
        {results.matchedKeys.map(key => <li key={key}><span style={styles.match}>{key}</span></li>)}
      </ul>
    )
  }

  const renderUnmatchedKeys = () => {
    if (!results || !results.unmatchedKeys || results.unmatchedKeys.length === 0) {
      console.log('No unmatched keys to render')
      return <p>No unmatched keys</p>
    }
    console.log('Rendering unmatched keys:', results.unmatchedKeys)
    return (
      <ul>
        {results.unmatchedKeys.map(key => <li key={key}><span style={styles.unmatch}>{key}</span></li>)}
      </ul>
    )
  }

  const renderTypeMismatches = () => {
    if (!results || !results.typeMismatches || Object.keys(results.typeMismatches).length === 0) {
      console.log('No type mismatches to render')
      return <p>No type mismatches</p>
    }
    console.log('Rendering type mismatches:', results.typeMismatches)
    return (
      <ul>
        {Object.entries(results.typeMismatches).map(([key, types]) => (
          <li key={key}>
            <span style={styles.unmatch}>
              {key}: {types.expected} (expected) vs {types.actual} (actual)
            </span>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout title="JSON Deep Compare Tool" seoDescription="Compare two JSON objects with detailed results">
        <Helmet title={`JSON Deep Compare Tool | ${config.siteTitle}`} />
        <div style={styles.container}>
          <h1 style={styles.title}>JSON Deep Compare Tool</h1>
          <p style={styles.description}>
            Compare two JSON objects and get detailed information about their differences.
            This tool uses the json-deep-compare library to perform advanced comparisons with configurable options.
          </p>
          
          <div style={styles.buttonGroup}>
            <button 
              style={{
                ...styles.actionButton(isDark),
                ...(hoveredButton === 'sample' ? styles.actionButtonHover(isDark) : {})
              }}
              onMouseEnter={() => setHoveredButton('sample')}
              onMouseLeave={() => setHoveredButton(null)}
              onClick={handleLoadSample}>
              Load Sample JSON
            </button>
          </div>
          
          <div style={isMobile ? styles.compareContainer : styles.compareContainerDesktop}>
            <div style={styles.jsonInputContainer}>
              <label style={styles.label}>First JSON:</label>
              <div style={styles.editorContainer}>
                <textarea 
                  style={styles.jsonEditor(isDark)} 
                  value={json1} 
                  onChange={(e) => setJson1(e.target.value)} 
                  placeholder="Paste your first JSON here"
                />
              </div>
            </div>
            
            <div style={styles.jsonInputContainer}>
              <label style={styles.label}>Second JSON:</label>
              <div style={styles.editorContainer}>
                <textarea 
                  style={styles.jsonEditor(isDark)} 
                  value={json2} 
                  onChange={(e) => setJson2(e.target.value)} 
                  placeholder="Paste your second JSON here"
                />
              </div>
            </div>
          </div>
          
          <div style={styles.configPanel(isDark)}>
            <h3 style={styles.configTitle}>Configuration Options</h3>
            <div style={styles.configGrid}>
              <div style={styles.configItem}>
                <label style={styles.label}>
                  <input 
                    type="checkbox" 
                    checked={strictTypeChecking} 
                    onChange={(e) => setStrictTypeChecking(e.target.checked)}
                  /> 
                  Strict Type Checking
                </label>
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Enforce exact type matching (number vs string, etc.)
                </small>
              </div>
              
              <div style={styles.configItem}>
                <label style={styles.label}>
                  <input 
                    type="checkbox" 
                    checked={ignoreExtraKeys} 
                    onChange={(e) => setIgnoreExtraKeys(e.target.checked)}
                  /> 
                  Ignore Extra Keys
                </label>
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Don't count extra fields in the second JSON as mismatches
                </small>
              </div>
              
              <div style={styles.configItem}>
                <label style={styles.label}>
                  <input 
                    type="checkbox" 
                    checked={useRegex} 
                    onChange={(e) => setUseRegex(e.target.checked)}
                  /> 
                  Use Regex Validation
                </label>
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Validate field values with regular expressions
                </small>
              </div>
              
              <div style={styles.configItem}>
                <label style={styles.label}>Keys to Ignore (comma-separated):</label>
                <input 
                  type="text" 
                  style={styles.input(isDark)}
                  value={ignoreKeys} 
                  onChange={(e) => setIgnoreKeys(e.target.value)} 
                  placeholder="e.g., timestamp,id,createdAt"
                />
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Fields that should be excluded from comparison
                </small>
              </div>
              
              <div style={styles.configItem}>
                <label style={styles.label}>Equivalent Values (JSON format):</label>
                <input 
                  type="text" 
                  style={styles.input(isDark)}
                  value={equivalentValues} 
                  onChange={(e) => setEquivalentValues(e.target.value)} 
                  placeholder='e.g., {"numericValues": [100, "100", 100.0]}'
                />
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Values that should be considered equivalent
                </small>
              </div>
              
              <div style={styles.configItem}>
                <label style={styles.label}>Regex Patterns (JSON format):</label>
                <input 
                  type="text" 
                  style={styles.input(isDark)}
                  value={regexPatterns} 
                  onChange={(e) => setRegexPatterns(e.target.value)} 
                  placeholder='e.g., {"email": "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}'
                  disabled={!useRegex}
                />
                <small style={{display: 'block', marginTop: '4px', opacity: 0.8}}>
                  Regular expressions to validate specific fields
                </small>
              </div>
            </div>
          </div>
          
          <button 
            style={{
              ...styles.compareButton,
              ...(hoveredButton === 'compare' ? styles.compareButtonHover : {})
            }}
            onMouseEnter={() => setHoveredButton('compare')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={handleCompare}>
            Compare
          </button>
          
          <div style={styles.resultsContainer(isDark, showResults)}>
            <h3 style={styles.configTitle}>Comparison Results</h3>
            
            {compareError && (
              <div style={{...styles.summaryBox(isDark), color: '#dc3545', marginBottom: '1rem'}}>
                <strong>Error:</strong> {compareError}
              </div>
            )}
            
            {results && (
              <>
                <div style={styles.buttonGroup}>
                  <button 
                    style={{
                      ...styles.actionButton(isDark),
                      ...(hoveredButton === 'copy' ? styles.actionButtonHover(isDark) : {})
                    }}
                    onMouseEnter={() => setHoveredButton('copy')}
                    onMouseLeave={() => setHoveredButton(null)}
                    onClick={handleCopyResults}>
                    Copy Results
                  </button>
                  <button 
                    style={{
                      ...styles.actionButton(isDark),
                      ...(hoveredButton === 'download' ? styles.actionButtonHover(isDark) : {})
                    }}
                    onMouseEnter={() => setHoveredButton('download')}
                    onMouseLeave={() => setHoveredButton(null)}
                    onClick={handleDownloadResults}>
                    Download Results
                  </button>
                </div>
                
                <div style={styles.resultSection}>
                  <h3>Summary</h3>
                  <div style={styles.summaryBox(isDark)}>
                    {results.matchPercentage !== undefined ? (
                      <p>Match Percentage: <strong>{(results.matchPercentage).toFixed(2)}%</strong></p>
                    ) : (
                      <p>Match Percentage: <strong>N/A</strong></p>
                    )}
                    <p>Matched Keys: <span style={styles.match}>{results.matchedKeys?.length || 0}</span></p>
                    <p>Unmatched Keys: <span style={styles.unmatch}>{results.unmatchedKeys?.length || 0}</span></p>
                    <p>Type Mismatches: <span style={styles.unmatch}>
                      {results.typeMismatches ? Object.keys(results.typeMismatches).length : 0}
                    </span></p>
                  </div>
                </div>
                
                <div style={styles.resultSection}>
                  <h3>Matched Keys</h3>
                  {renderMatchedKeys()}
                </div>
                
                <div style={styles.resultSection}>
                  <h3>Unmatched Keys</h3>
                  {renderUnmatchedKeys()}
                </div>
                
                <div style={styles.resultSection}>
                  <h3>Type Mismatches</h3>
                  {renderTypeMismatches()}
                </div>
                
                {results.regexValidationFailures && Object.keys(results.regexValidationFailures).length > 0 && (
                  <div style={styles.resultSection}>
                    <h3>Regex Validation Failures</h3>
                    <ul>
                      {Object.entries(results.regexValidationFailures).map(([key, value]) => (
                        <li key={key}><span style={styles.unmatch}>{key}: {value}</span></li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default JsonCompareTool
